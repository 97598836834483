import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import React, { FunctionComponent } from 'react';

import { useTranslation } from '../../../../hooks/useTranslation';
import useStyles from '../GuestPaymentForm.styles';
import EmailTextField from '../../../email-text-field';
import PhoneTextField from '../../../phone-text-field';
import { FormState } from '../../../../hooks/useFormState.types';
import { Model } from '../GuestPaymentForm.rules';
import EitherField from '../../../either-field';

interface Props {
  form: FormState<Model>;
}

const GuestPaymentNotificationSection: FunctionComponent<Props> = props => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { form } = props;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} style={{ marginTop: '1em' }}>
        <Typography>{t('GUEST_PAY_CONFIRMATION_SUBTITLE')}</Typography>
      </Grid>
      <Grid item xs={12}>
        <EitherField
          aria-label="select notification method"
          form={form}
          selectorField="notificationChannel"
          inputFields={['notificationEmail', 'notificationTextNumber']}
          renderLeft={
            <EmailTextField
              name={'notificationEmail'}
              label={t('EMAIL_ADDRESS')}
              autoComplete={t('EMAIL_ADDRESS')}
            />
          }
          renderRight={
            <PhoneTextField
              name={'notificationTextNumber'}
              label={t('TEXT_PHONE_NUMBER')}
              autoComplete={t('TEXT_PHONE_NUMBER')}
            />
          }
          rightHelper={
            <div className={classes.radioSubtitle}>
              <Typography variant={'caption'}>
                {t('TEXT_MESSAGE_COPY')}
              </Typography>
            </div>
          }
        />
      </Grid>
    </Grid>
  );
};

export default GuestPaymentNotificationSection;
