import { Typography, Button, Divider } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import React, { FunctionComponent, useEffect } from 'react';
import { useTranslation } from '../../../hooks/useTranslation';
import ROUTES from '../../../routes';
import { FormState } from '../../../hooks/useFormState.types';
import { GuestPaymentAccount } from '../GuestPaymentTypes';
import { navigate } from '@reach/router';
import { PaymentProfileInfo } from '../../paymentus/types';
import { Model } from '../guest-payment-form/GuestPaymentForm.rules';
import useStyles from './GuestPaymentMethod.styles';
import GuestPaymentHeaderSection from '../guest-payment-form/guest-payment-header-section/GuestPaymentHeaderSection';
import AddPaymentMethodDropdown from '../../payment-method-type/AddPaymentMethodDropdown';
import PaymentMethodTypeIconList from '../../payment-method-type/PaymentMethodTypeIconList';
import { PaymentCategory } from '../../../__generated__/pge-types';

interface Props {
  path?: string;
  guestPaymentForm: FormState<Model>;
  guestPaymentAccount: GuestPaymentAccount;
  paymentDate: string;
  handleOnGuestPayBack: () => void;
  handleOnGuestPayContinue: (profile: PaymentProfileInfo) => void;
}

const GuestPaymentMethod: FunctionComponent<Props> = props => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    guestPaymentForm,
    guestPaymentAccount,
    paymentDate,
    handleOnGuestPayBack,
    handleOnGuestPayContinue,
  } = props;
  const addOrUpdateProps = {
    customLabel: t('ENTER_A_PAYMENT_METHOD'),
    currentProfileInfo: guestPaymentForm.values.paymentProfile,
    isPrimaryPM: false,
    onChange: (profile: PaymentProfileInfo) => {
      handleOnGuestPayContinue(profile);
    },
    onNew: (profile: PaymentProfileInfo) => {
      handleOnGuestPayContinue(profile);
    },
    allowedCategories: [
      PaymentCategory.Dd,
      PaymentCategory.Cc,
      PaymentCategory.Dc,
      PaymentCategory.PaypalAccount,
      PaymentCategory.AmazonPay,
    ],
  };
  const labelComponent = (
    <Typography style={{ float: 'left' }}> + {t('WE_ACCEPT')}</Typography>
  );

  useEffect(() => {
    if (Number(guestPaymentForm.values.amount) <= 0) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      navigate(ROUTES.GUEST_PAY);
    }
  }, []);

  return (
    <Grid container spacing={1} className={classes.mainContainer}>
      <div style={{ padding: '1em' }} />
      <Grid item xs={12}>
        <Typography variant={'h1'}>{t('GUEST_PAYMENT')}</Typography>
      </Grid>
      <div style={{ padding: '.25em' }} />
      <GuestPaymentHeaderSection
        accountNumber={guestPaymentAccount.maskedAccountNumber}
        partialAddress={guestPaymentAccount.partialAddressLineOne}
        zipCode={guestPaymentAccount.zipCode}
      />
      <div style={{ padding: '.75em' }} />
      <Paper>
        <Card>
          <CardContent>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant={'h4'} color={'primary'}>
                  {t('ENTER_PAYMENT_INFORMATION')}
                </Typography>
              </Grid>
              <Grid item xs={12} style={{ marginTop: '2em' }}>
                <Typography variant={'h3'}>
                  Payment date: {paymentDate}
                </Typography>
              </Grid>
              <Grid item xs={12} className={classes.payContainer}>
                <Typography variant={'h3'}>
                  Pay: $ {guestPaymentForm.values.amount}
                </Typography>
              </Grid>
            </Grid>
            <Divider />
            <div style={{ padding: '.75em' }} />
            <AddPaymentMethodDropdown {...addOrUpdateProps} />
            <PaymentMethodTypeIconList labelComponent={labelComponent} />
          </CardContent>
        </Card>
      </Paper>
      <Grid item xs={12} className={classes.buttonContainer}>
        <Button
          onClick={handleOnGuestPayBack}
          variant={'outlined'}
          color={'primary'}
          size={'large'}
        >
          {t('BACK')}
        </Button>
      </Grid>
    </Grid>
  );
};

export default GuestPaymentMethod;
