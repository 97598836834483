import { navigate } from 'gatsby';
import { useEffect } from 'react';

export default (timeout = 5000, redirectUrl = '/'): any => {
  useEffect(() => {
    const timer = setTimeout(() => {
      console.info(`Session expired after ${timeout}ms. Redirecting...`);
      return navigate(redirectUrl);
    }, timeout);
    return () => clearTimeout(timer);
  });
};
