import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { navigate } from '@reach/router';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { FormState } from '../../../hooks/useFormState.types';
import { useTranslation } from '../../../hooks/useTranslation';
import ROUTES from '../../../routes';
import { GuestPaymentAccount } from '../GuestPaymentTypes';
import useStyles from './GuestPayConfirmation.styles';
import GuestPaymentNotificationSection from '../guest-payment-form/guest-paymen-notification-section/GuestPaymentNotificationSection';
import MaskedNameTextField from '../../masked-name-text-field/MaskedNameTextField';
import TextLink from '../../text-link/TextLink';
import colors from '../../../themes/main-colors';
import { Box, Button } from '@material-ui/core';
import {
  Model,
  NotificationChannel,
} from '../guest-payment-form/GuestPaymentForm.rules';
import { Model as AuthModel } from '../guest-payment-form/GuestPaymentAuthForm.rules';
import {
  displayPhoneNumberFormat,
  maskAllButLast,
  replaceAsteriskForMarkdown,
} from '../../../util/format';
import { isMobile } from '../../../util/style-utils';
import ProceedOrCancel from '../../proceed-or-cancel/ProceedOrCancel';
import { getAccountDisplayString } from '../../paymentus/utils';
import {
  getPaymentCategoryLabel,
  getPaymentMethodLabel,
} from '../../../hooks/usePaymentus';

interface Props {
  path?: string;
  guestPaymentForm: FormState<Model>;
  guestPaymentAccount: GuestPaymentAccount;
  paymentDate: string;
  handleOnSubmit: () => Promise<void>;
  isInFlight: boolean;
  handleOnCancel: (e: any) => Promise<void>;
  guestPaymentAuthForm: FormState<AuthModel>;
}

const GuestPayConfirmation: FunctionComponent<Props> = props => {
  const classes = useStyles();
  const { t, richT } = useTranslation();
  const [checked, toggleChecked] = useState(false);
  const {
    guestPaymentForm,
    guestPaymentAccount,
    paymentDate,
    handleOnSubmit,
    isInFlight,
    handleOnCancel,
    guestPaymentAuthForm,
  } = props;

  useEffect(() => {
    if (Number(guestPaymentForm.values.amount) <= 0) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      navigate(ROUTES.GUEST_PAY);
    }
  }, []);

  const notificationChannelText =
    guestPaymentForm.values.notificationChannel === NotificationChannel.EMAIL
      ? t('EMAIL')
      : t('TEXT');
  const notificationContactValue =
    guestPaymentForm.values.notificationChannel === NotificationChannel.EMAIL
      ? guestPaymentForm.values.notificationEmail
      : displayPhoneNumberFormat(
          guestPaymentForm.values.notificationTextNumber,
        );

  const escapedMaskedBankAccountNumber = getAccountDisplayString(
    guestPaymentForm.values.paymentProfile?.profile,
  );
  const paymentAmountDisplay = `$${guestPaymentForm.values.amount}`;
  const selectedPaymentCategory =
    guestPaymentForm.values.paymentProfile?.pmCategory;
  const paymentMethodToDisplay =
    selectedPaymentCategory === 'DD'
      ? t('BANK_ACCOUNT_NUMBER')
      : selectedPaymentCategory === 'CC'
      ? t('CREDIT_CARD_NUMBER')
      : selectedPaymentCategory === 'DC'
      ? t('DEBIT_CARD_NUMBER')
      : getPaymentMethodLabel(
          guestPaymentForm.values.paymentProfile?.profile?.type,
        );

  const paymentCategoryToDisplay = getPaymentCategoryLabel(
    guestPaymentForm.values.paymentProfile?.profile?.type,
    true,
  );

  return (
    <Grid container spacing={1} className={classes.root}>
      <Grid item xs={12} style={{ padding: '1em' }}>
        <Typography className={classes.colorTextPrimary} variant={'h1'}>
          {t('GUEST_PAYMENT')}
        </Typography>
      </Grid>
      <Card>
        <CardContent>
          <Grid item xs={12}>
            <Typography className={classes.colorTextPrimary} variant={'h2'}>
              {t('VERIFY_PAYMENT')}
            </Typography>
          </Grid>
          <Grid item className={classes.infoBoxContent} xs={12}>
            <Grid item xs={12} className={classes.infoBoxLeftPane}>
              <Grid item xs={12} style={{ marginBottom: '.75em' }}>
                <Typography
                  className={classes.colorTextPrimary}
                  variant={'body1'}
                >
                  {t('SERVICE_ADDRESS_BEGINS_WITH')}
                </Typography>
                <Typography
                  className={classes.colorTextPrimary}
                  variant={'subtitle2'}
                >
                  {guestPaymentAccount.partialAddressLineOne}
                </Typography>
              </Grid>
              <Grid item xs={12} style={{ marginBottom: '.75em' }}>
                <Typography
                  className={classes.colorTextPrimary}
                  variant={'body1'}
                >
                  {t('PAYMENT_DATE')}
                </Typography>
                <Typography
                  className={classes.colorTextPrimary}
                  variant={'subtitle2'}
                >
                  {paymentDate}
                </Typography>
              </Grid>
              <Grid item xs={12} style={{ marginBottom: '.75em' }}>
                <Typography
                  className={classes.colorTextPrimary}
                  variant={'body1'}
                >
                  {t('PAYMENT_AMOUNT')}
                </Typography>
                <Typography
                  className={classes.colorTextPrimary}
                  variant={'subtitle2'}
                >
                  $ {guestPaymentForm.values.amount}
                </Typography>
              </Grid>
            </Grid>

            <Grid xs={12} item className={classes.infoBoxRightPane}>
              <Grid item xs={12} style={{ marginBottom: '.75em' }}>
                <Typography
                  className={classes.colorTextPrimary}
                  variant={'body1'}
                >
                  {paymentMethodToDisplay}
                </Typography>
                <Typography
                  className={classes.colorTextPrimary}
                  variant={'subtitle2'}
                >
                  {escapedMaskedBankAccountNumber ||
                    `${t('GUEST_PAY_AUTHORIZED')}`}
                </Typography>
              </Grid>
              <Grid item xs={12} style={{ marginBottom: '.75em' }}>
                <Typography
                  className={classes.colorTextPrimary}
                  variant={'body1'}
                >
                  {t('PAYMENT_CONFIRMATION_METHOD')}
                </Typography>
                <Typography
                  className={classes.colorTextPrimary}
                  variant={'subtitle2'}
                >
                  {notificationChannelText}: {notificationContactValue}
                </Typography>
              </Grid>
              <Grid item xs={12}></Grid>
            </Grid>
          </Grid>
          <Divider />
          <Grid item xs={12} style={{ marginTop: '1.5em' }}>
            <Typography>{t('AUTHORIZED_BY')}</Typography>
          </Grid>
          <Grid item xs={12} style={{ marginTop: '1em' }}>
            <MaskedNameTextField
              name={'fullName'}
              label={t('FULL_NAME')}
              style={{ width: '100%' }}
              {...guestPaymentAuthForm.props('fullName')}
              defaultValue={guestPaymentAuthForm.values.fullName}
            />
          </Grid>
          <Grid item className={classes.authorizationBox}>
            <Grid item className={classes.checkWrapper}>
              <Grid
                item
                className={classes.checkBox}
                style={{ marginTop: '.8em' }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      name={'accept'}
                      color={'primary'}
                      data-testid={'guestpay-checkbox'}
                      onChange={() => toggleChecked(!checked)}
                    />
                  }
                  label={''}
                />
              </Grid>
              <Grid item className={classes.checkBox}>
                <Typography
                  component={'span'}
                  className={classes.colorTextPrimary}
                  variant={'body1'}
                >
                  {richT(
                    'BY_CLICKING_SUBMIT_AUTHORIZATION_STATEMENT_GUESTPAY_NEW',
                    {
                      MASKED_BANK_ACCOUNT_NUMBER:
                        // escapedMaskedBankAccountNumber is a 10 char string (6 asterisks + 4 digits)
                        // react-markdown 4.3.1 has a bug or limitation where if the number of contiguous
                        // asterisks N > 4, then it seems to be able to render only (N-1)%4 + 1 asterisks.
                        // Replacing asterisk with its html entity helps fix this issue for 4.3.1
                        // TODO - remove replaceAsteriskForMarkdown when we update react-markdown to 5.x
                        escapedMaskedBankAccountNumber
                          ? replaceAsteriskForMarkdown(
                              escapedMaskedBankAccountNumber,
                            ) || undefined
                          : undefined,
                      PAYMENT_AMOUNT: paymentAmountDisplay,
                      PAYMENT_METHOD_TYPE: paymentCategoryToDisplay,
                    },
                  )}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Grid
        container
        item
        xs={12}
        className={classes.buttonContainer}
        spacing={1}
      >
        <Grid item xs={12} md="auto">
          <Box className={classes.cancelVerificationLink}>
            <TextLink
              onClick={handleOnCancel}
              style={{ textDecoration: 'none' }}
            >
              {t('CANCEL')}
            </TextLink>
          </Box>
        </Grid>
        <Grid item xs={12} md="auto">
          <Box>
            <ProceedOrCancel
              proceedHandler={handleOnSubmit}
              cancelRoute={ROUTES.GUEST_PAYMENT_METHOD}
              cancelLabel={t('BACK')}
              cancelVariant="Button"
              proceedLabel={t('PAY_BILL')}
              proceedStyle={colors.orange}
              proceedDisabled={!checked || isInFlight}
            />
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default GuestPayConfirmation;
