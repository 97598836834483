import { ApolloClient } from 'apollo-client';
import {
  validateAmount,
  validateBankAccount,
  validateConfirmBankAccount,
  validateEmail,
  validateNameField,
  validatePhoneNumber,
  validateRoutingNumber,
} from '../../../util/form-validation';
import { convertValidationRules } from '../../../hooks/useFormState';
import { PaymentProfileInfo } from '../../paymentus/types';

export enum NotificationChannel {
  EMAIL = 'notificationEmail',
  TEXT = 'notificationTextNumber',
}
export type Model = {
  // this must match the names of the form field
  amount: string;
  notificationChannel: NotificationChannel;
  notificationEmail: string;
  notificationTextNumber: string;
  paymentProfile?: PaymentProfileInfo;
};

type AdditionalContext = {
  apolloClient: ApolloClient<any>;
  t: (key: string) => string;
};

export default convertValidationRules<Model, AdditionalContext>(context => {
  const { notificationChannel } = context.values;

  return {
    // this must match the names of the form fields
    amount: validateAmount,
    notificationEmail:
      notificationChannel === NotificationChannel.EMAIL ? validateEmail : null,
    notificationTextNumber:
      notificationChannel === NotificationChannel.TEXT
        ? validatePhoneNumber
        : null,
  };
});
