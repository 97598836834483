import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

import colors from '../../../themes/main-colors';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 286,
      color: colors.noirBlur,
      display: 'flex',
      flexDirection: 'column',
    },
    '& .MuiFormControl-marginNormal': {
      marginTop: '8px',
    },
    nextButtonBox: {
      marginRight: 0,
    },
    nextButton: {
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
      [theme.breakpoints.down('sm')]: {
        width: '70%',
      },
      [theme.breakpoints.up('md')]: {
        width: 100,
      },
      [theme.breakpoints.up('lg')]: {
        width: 100,
      },
    },
    zipCode: {
      width: '80%',
    },
    phoneAndAccount: {
      margin: 0,
    },
    phoneOrAccountNumber: {
      padding: theme.spacing(1),
      [theme.breakpoints.down('xs')]: {
        display: 'flex',
        flexDirection: 'column',
      },
      [theme.breakpoints.up('sm')]: {
        margin: 0,
        display: 'flex',
        flexDirection: 'column',
      },
      [theme.breakpoints.up('md')]: {
        margin: 0,
        display: 'flex',
        flexDirection: 'row',
      },
      [theme.breakpoints.up('lg')]: {
        margin: 0,
        display: 'flex',
        flexDirection: 'row',
      },
    },
    or: {
      justifyContent: 'center',
      display: 'flex',
      [theme.breakpoints.down('md')]: {
        marginLeft: '0em',
        marginTop: '1em',
      },
      [theme.breakpoints.up('md')]: {
        marginTop: '5em',
      },
    },
    colorTextPrimary: {
      color: colors.noirBlur,
    },
    radioSubtitle: {
      marginLeft: theme.spacing(5.5),
    },
    buttonContainer: {
      display: 'flex',
      flexDirection: 'row-reverse',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      [theme.breakpoints.down('xs')]: {
        justifyContent: 'center',
      },
    },
    marginBot1: {
      marginBottom: '1em',
    },
    followContainer: {
      marginTop: '1em',
      marginBottom: '.5em',
    },
  }),
);

export default useStyles;
