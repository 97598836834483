import gql from 'not-graphql-tag';

export const verifyGuestPayAccountQuery = gql`
  query getGuestPaymentInfo($params: GuestPaymentAccountInput!) {
    getGuestPaymentInfo(params: $params) {
      guestAccountDetails {
        isFound
        foundMultiple
      }
      eligibility {
        isEligible
      }
      paymentInfo {
        encryptedAccountNumber
        lastFourdDigitAccount
        amountDue
        houseNumber
      }
    }
  }
`;

export const getGuestPayInfoQuery = gql`
  query getGuestPayInfo($encryptedAccountNumber: String) {
    getGuestPayInfo(encryptedAccountNumber: $encryptedAccountNumber) {
      AccountNumber
      AmountDue
      DueDate
      PaymentDate
      ServiceAddress
      CustomerName
      GuestEmailAddress
      GuestTextPhone
      EncryptedBankAccountNumber
      BankAccountNumber
      MaskedBankAccountNumber
      BankRoutingNumber
    }
  }
`;

export const submitGuestPayMutation = gql`
  mutation submitGuestPayment($payload: GuestPayInput!) {
    submitGuestPayment(payload: $payload) {
      confirmationId
      isSuccess
      paymentAmount
      errorReason
      errorMessage
    }
  }
`;

export const getGuestPayPDFQuery = gql`
  query getGuestPayPDF($params: guestPayPDFParams) {
    getGuestPayPDF(params: $params) {
      PDF
    }
  }
`;
