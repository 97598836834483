import { Router } from '@reach/router';
import React from 'react';
import AuthLayout from '../../components/auth-layout';
import useGuestPayForm from '../../components/guest-pay/useGuestPayForm';
import GuestPayAccountFinder from '../../components/guest-pay/guest-pay-account-finder/GuestPayAccountFinder';
import GuestPaymentForm from '../../components/guest-pay/guest-payment-form';
import GuestPayConfirmation from '../../components/guest-pay/guest-pay-confirmation';
import GuestPayTransactionSuccessful from '../../components/guest-pay/guest-pay-transaction-successful';
import { useTranslation } from '../../hooks/useTranslation';
import Helmet from 'react-helmet';
import PrivateRoute from '../../components/routing/PrivateRoute';
import NotFoundPage from '../404';
import NavPaneWithSignIn from '../../components/nav-pane/NavPaneWithSignIn';
import { usePlannedDownTimeRedirect } from '../../hooks/usePlannedDownTimeRedirect';
import { CheckCookiesEnabled } from '../../components/browser-check';
import ROUTES from '../../routes';
import GuestPaymentMethod from '../../components/guest-pay/guest-payment-method/GuestPaymentMethod';

export const GuestPayPage = () => {
  const { t } = useTranslation();
  usePlannedDownTimeRedirect();
  const props = useGuestPayForm();

  return (
    <CheckCookiesEnabled>
      <AuthLayout footer={' '} sideNav={<NavPaneWithSignIn />}>
        <Helmet>
          <title>{t('GUEST_PAYMENT')}</title>
        </Helmet>
        <Router>
          <GuestPayAccountFinder
            path={ROUTES.GUEST_PAY}
            form={props.accountFinderForm}
            handleOnSubmit={props.handleOnAccountFinderSubmit}
            accountNotFound={props.accountNotFound}
            isIneligible={props.isInelgible}
          />
          <GuestPaymentForm
            path={ROUTES.GUEST_PAYMENT_FORM}
            guestPaymentForm={props.guestPaymentForm}
            guestPaymentAccount={props.guestPaymentAccount}
            handleOnGuestPayNext={props.handleOnGuestPayNext}
            paymentDate={props.paymentDate}
            handleOnGuestPayCancel={props.clearGuestPaymentData}
          />
          <GuestPaymentMethod
            path={ROUTES.GUEST_PAYMENT_METHOD}
            guestPaymentForm={props.guestPaymentForm}
            guestPaymentAccount={props.guestPaymentAccount}
            paymentDate={props.paymentDate}
            handleOnGuestPayBack={props.handleOnGuestPayBack}
            handleOnGuestPayContinue={props.handleOnGuestPayContinue}
          />
          <GuestPayConfirmation
            path={ROUTES.GUEST_PAY_CONFIRMATION}
            guestPaymentAccount={props.guestPaymentAccount}
            guestPaymentForm={props.guestPaymentForm}
            paymentDate={props.paymentDate}
            handleOnSubmit={props.handleOnGuestPaymentSubmit}
            isInFlight={props.isInFlight}
            handleOnCancel={props.handleOnGuestPayCancelFromVerification}
            guestPaymentAuthForm={props.guestPaymentAuthForm}
          />
          <GuestPayTransactionSuccessful
            path={ROUTES.GUEST_PAY_SUCCESS}
            isSuccessful={props.transactionResults.isSuccessful}
            amount={props.transactionResults.amount}
            confirmationNumber={props.transactionResults.confirmationNumber}
            notificationContactValue={
              props.transactionResults.notificationContactValue
            }
            handleDoneClick={props.handleDoneClick}
          />
          <PrivateRoute path="/*" component={NotFoundPage} />
        </Router>
      </AuthLayout>
    </CheckCookiesEnabled>
  );
};

export default GuestPayPage;
