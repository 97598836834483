import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

import colors from '../../../themes/main-colors';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 286,
      color: colors.noirBlur,
    },
    nextButtonBox: {
      marginRight: 0,
    },
    colorTextPrimary: {
      color: colors.noirBlur,
    },
    nextButton: {
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        marginRight: 8,
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        marginRight: 8,
      },
      [theme.breakpoints.up('md')]: {
        width: 100,
      },
      [theme.breakpoints.up('lg')]: {
        width: 100,
      },
    },
    confirmationCopy: {
      color: colors.noirBlur,
      display: 'flex',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
      [theme.breakpoints.up('sm')]: {
        flexDirection: 'row',
      },
      [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
      },
      [theme.breakpoints.up('lg')]: {
        flexDirection: 'row',
      },
    },
    confirmationNumber: {
      color: colors.noirBlur,
      marginLeft: '.5em',
      [theme.breakpoints.down('xs')]: {
        marginLeft: 0,
      },
      [theme.breakpoints.up('sm')]: {
        marginLeft: 3,
        marginRight: 3,
      },
      [theme.breakpoints.up('md')]: {
        marginLeft: 3,
        marginRight: 3,
      },
      [theme.breakpoints.up('lg')]: {
        marginLeft: 3,
        marginRight: 3,
      },
    },
    zipCode: {
      width: '50%',
    },
    phoneAndAccount: {
      margin: 0,
    },
    phoneOrAccountNumber: {
      padding: theme.spacing(1),
      [theme.breakpoints.down('xs')]: {
        display: 'flex',
        flexDirection: 'column',
      },
      [theme.breakpoints.up('sm')]: {
        margin: 0,
        display: 'flex',
        flexDirection: 'column',
      },
      [theme.breakpoints.up('md')]: {
        margin: 0,
        display: 'flex',
        flexDirection: 'row',
      },
      [theme.breakpoints.up('lg')]: {
        margin: 0,
        display: 'flex',
        flexDirection: 'row',
      },
    },
    submitButton: {
      display: 'flex',
      justifyContent: 'flex-end',
      [theme.breakpoints.up('sm')]: {
        justifyContent: 'center',
      },
      [theme.breakpoints.down('xs')]: {
        width: '70%',
        justifyContent: 'center',
        margin: 5,
      },
    },
  }),
);

export default useStyles;
