import { navigate } from 'gatsby';
import { useState, useContext, useEffect } from 'react';
import {
  verifyGuestPayAccountQuery,
  submitGuestPayMutation,
} from '../../queries/guestpay.query';
import ROUTES from '../../routes';
import {
  parsePhoneNumber,
  maskAccountNumber,
  displayPhoneNumberFormat,
  formatDate,
} from '../../util/format';
import {
  getFromSessionStorage,
  setInSessionStorage,
  removeFromSessionStorage,
} from '../../util/storage-utils';
import useFormState from '../../hooks/useFormState';
import guestPaymentAccountLookupValidationRules, {
  Model as AccountFinderModel,
} from './guest-pay-account-finder/GuestPayAccountFinder.rules';
import guestPaymentFormValidationRules, {
  Model as PaymentFormModel,
  NotificationChannel,
} from './guest-payment-form/GuestPaymentForm.rules';
import guestPaymentAuthFormValidationRules, {
  Model as PaymentAuthFormModel,
} from './guest-payment-form/GuestPaymentAuthForm.rules';
import { IdentificationType } from '../../__generated__/pge-types';
import { useApolloClient, useMutation } from '@apollo/react-hooks';
import { useTranslation } from '../../hooks/useTranslation';
import { GuestPaymentAccount, TransactionResults } from './GuestPaymentTypes';
import useSessionTimeout from '../../hooks/useSessionTimeout';
import useWrapWithLoader from '../../hooks/useWrapWithLoading';
import { NotificationsContext } from '../../providers/NotificationsProvider';
import { PaymentProfileInfo } from '../paymentus/types';
import { validateAmount } from '../../util/form-validation';
import moment from 'moment-timezone';

const getFormattedDate = (date = new Date()) => {
  const year = date.getFullYear();
  const month = (1 + date.getMonth()).toString().padStart(2, '0');
  const day = date
    .getDate()
    .toString()
    .padStart(2, '0');

  return month + '/' + day + '/' + year;
};

const defaultAccountFinderFormValues: AccountFinderModel = {
  zipCode: '',
  identificationType: IdentificationType.PhoneNumber,
  accountNumber: '',
  phoneNumber: '',
};

const GuestPaymentAccountStorageKey = 'guest-payment-account-storage-key';

const defaultGuestPaymentAccount: GuestPaymentAccount = {
  encryptedAccountNumber: '',
  maskedAccountNumber: '',
  partialAddressLineOne: '',
  zipCode: '',
  amountDue: '',
};

const setGuestPaymentAccountStorage = (
  guestPaymentAccount: GuestPaymentAccount,
): void => {
  setInSessionStorage(
    GuestPaymentAccountStorageKey,
    JSON.stringify(guestPaymentAccount),
  );
};

const getDefaultGuestPaymentAccount = (): GuestPaymentAccount => {
  const formFromSessionStorage =
    Boolean(getFromSessionStorage(GuestPaymentAccountStorageKey)) &&
    JSON.parse(getFromSessionStorage(GuestPaymentAccountStorageKey));
  return Boolean(formFromSessionStorage)
    ? formFromSessionStorage
    : defaultGuestPaymentAccount;
};

const defaultPaymentFormValues: PaymentFormModel = {
  amount: '',
  notificationChannel: NotificationChannel.EMAIL,
  notificationEmail: '',
  notificationTextNumber: '',
};

const defaultPaymentAuthFormValues: PaymentAuthFormModel = {
  fullName: '',
};

export default () => {
  const twentyMinutes = 1000 * 60 * 20;
  useSessionTimeout(twentyMinutes, ROUTES.HOME);
  const notificationContext = useContext(NotificationsContext);
  const { wrapWithLoader } = useWrapWithLoader();
  const apolloClient = useApolloClient();
  const { t } = useTranslation();

  const [guestPaymentAccount, setGuestPaymentAccount] = useState<
    GuestPaymentAccount
  >(getDefaultGuestPaymentAccount());

  const accountFinderForm = useFormState(defaultAccountFinderFormValues, {
    validate: guestPaymentAccountLookupValidationRules,
  });

  const guestPaymentForm = useFormState(defaultPaymentFormValues, {
    validate: guestPaymentFormValidationRules,
    validationContext: {
      apolloClient,
      t,
    },
  });
  const guestPaymentAuthForm = useFormState(defaultPaymentAuthFormValues, {
    validate: guestPaymentAuthFormValidationRules,
    validationContext: {
      apolloClient,
      t,
    },
  });

  const clearGuestPaymentData = (): void => {
    removeFromSessionStorage(GuestPaymentAccountStorageKey);
    setGuestPaymentAccount(defaultGuestPaymentAccount);
    guestPaymentForm.reset(defaultPaymentFormValues);
    guestPaymentAuthForm.reset(defaultPaymentAuthFormValues);
  };

  const [accountNotFound, setAccountNotFound] = useState(false);
  const [isInelgible, setIsIneligible] = useState(false);
  const [isInFlight, setIsInFlight] = useState(false);
  const [transactionResults, setTransactionResults] = useState<
    TransactionResults
  >({
    isSuccessful: false,
    amount: '',
    confirmationNumber: '',
    notificationContactValue: '',
  });

  function setErrorNotification(
    showNotification: boolean,
    errorMessage?: string,
  ) {
    const message = showNotification
      ? Boolean(errorMessage)
        ? t(errorMessage!)
        : t('GENERIC_ERROR_NOTIFICATION_MESSAGE_BODY')
      : null;
    notificationContext.setState({
      isOpen: showNotification,
      message: showNotification ? message : undefined,
      severity: showNotification ? 'error' : undefined,
    });
  }

  const verifyGuestPayAccount = async (): Promise<void> => {
    setAccountNotFound(false);
    setIsIneligible(false);

    const queryResult = await apolloClient.query({
      query: verifyGuestPayAccountQuery,
      variables: {
        params: {
          zipCode: accountFinderForm.values.zipCode,
          identificationType: accountFinderForm.values.identificationType,
          accountNumber:
            accountFinderForm.values.identificationType ===
            IdentificationType.AccountNumber
              ? accountFinderForm.values.accountNumber
              : '',
          phoneNumber:
            accountFinderForm.values.identificationType ===
            IdentificationType.PhoneNumber
              ? displayPhoneNumberFormat(accountFinderForm.values.phoneNumber)
              : '',
        },
      },
      fetchPolicy: 'network-only',
    });

    const guestPaymentInfo = queryResult?.data?.getGuestPaymentInfo;

    if (!Boolean(guestPaymentInfo?.guestAccountDetails?.isFound)) {
      setAccountNotFound(true);
      return;
    }

    if (!Boolean(guestPaymentInfo?.eligibility?.isEligible)) {
      setIsIneligible(true);
      return;
    }

    const guestPaymentAccountDetails: GuestPaymentAccount = {
      maskedAccountNumber:
        '******' + guestPaymentInfo?.paymentInfo?.lastFourdDigitAccount,
      encryptedAccountNumber:
        guestPaymentInfo?.paymentInfo?.encryptedAccountNumber,
      partialAddressLineOne: guestPaymentInfo?.paymentInfo?.houseNumber
        ? guestPaymentInfo?.paymentInfo?.houseNumber
        : '',
      zipCode: accountFinderForm.values.zipCode,
      amountDue:
        !isNaN(guestPaymentInfo?.paymentInfo?.amountDue) &&
        guestPaymentInfo?.paymentInfo?.amountDue > 0
          ? String(Number(guestPaymentInfo?.paymentInfo?.amountDue).toFixed(2))
          : '0',
    };

    setGuestPaymentAccountStorage(guestPaymentAccountDetails);
    setGuestPaymentAccount(guestPaymentAccountDetails);

    guestPaymentForm.reset({
      ...guestPaymentForm.values,
      amount: guestPaymentAccountDetails.amountDue,
    });

    await navigate(ROUTES.GUEST_PAYMENT_FORM);
  };

  const [submitGuestPayment, {}] = useMutation(submitGuestPayMutation, {
    variables: {
      payload: {
        encryptedAccountNumber: guestPaymentAccount.encryptedAccountNumber,
        paymentAmount: Number(
          Number(guestPaymentForm.values.amount).toFixed(2),
        ),
        customerName: guestPaymentAuthForm.values.fullName,
        tokenId: guestPaymentForm.values.paymentProfile?.profile.token,
        guestEmailAddress:
          guestPaymentForm.values.notificationChannel ===
          NotificationChannel.EMAIL
            ? guestPaymentForm.values.notificationEmail
            : '',
        guestTextPhone:
          guestPaymentForm.values.notificationChannel ===
          NotificationChannel.TEXT
            ? guestPaymentForm.values.notificationTextNumber
            : '',
      },
    },
  });

  const handleOnAccountFinderSubmit = async () => {
    clearGuestPaymentData();
    await wrapWithLoader(
      accountFinderForm.submit(async () => {
        await verifyGuestPayAccount();
      }),
    )();
  };

  const handleOnGuestPayNext = async () => {
    await wrapWithLoader(
      guestPaymentForm.submit(async () => {
        await navigate(ROUTES.GUEST_PAYMENT_METHOD);
      }),
    )();
  };

  const handleOnGuestPayBack = async (): Promise<void> => {
    await navigate(ROUTES.GUEST_PAYMENT_FORM);
  };

  const handleOnGuestPayBackFromVerification = async (): Promise<void> => {
    await navigate(ROUTES.GUEST_PAYMENT_METHOD);
  };

  const handleOnGuestPayContinue = wrapWithLoader(
    async (profile: PaymentProfileInfo) => {
      guestPaymentForm.values.paymentProfile = profile;
      await navigate(ROUTES.GUEST_PAY_CONFIRMATION);
    },
  );

  const handleOnGuestPayCancelFromVerification = wrapWithLoader(async e => {
    e.preventDefault();
    clearGuestPaymentData();
    accountFinderForm.reset(defaultAccountFinderFormValues);
    await navigate(ROUTES.GUEST_PAY);
  });

  const handleOnGuestPaymentSubmit = wrapWithLoader(
    guestPaymentAuthForm.submit(async () => {
      try {
        setIsInFlight(true);

        const response = await submitGuestPayment();
        const submitResults = response?.data?.submitGuestPayment;
        const success = Boolean(submitResults?.isSuccess);

        setTransactionResults({
          isSuccessful: success,
          amount: success ? submitResults?.paymentAmount : '',
          confirmationNumber: success ? submitResults?.confirmationId : '',
          notificationContactValue: success
            ? guestPaymentForm.values.notificationChannel ===
              NotificationChannel.EMAIL
              ? guestPaymentForm.values.notificationEmail
              : displayPhoneNumberFormat(
                  guestPaymentForm.values.notificationTextNumber,
                )
            : '',
        });

        if (success) {
          clearGuestPaymentData();
          accountFinderForm.reset(defaultAccountFinderFormValues);

          typeof window !== 'undefined' &&
            window.history.pushState(null, '', ROUTES.GUEST_PAY);

          await navigate(ROUTES.GUEST_PAY_SUCCESS);
        } else {
          window.scrollTo(0, 0);
          setErrorNotification(true, submitResults.errorMessage);
        }
      } catch {
        window.scrollTo(0, 0);
        setErrorNotification(true);
      } finally {
        setIsInFlight(false);
      }
    }),
  );

  const handleDoneClick = async (): Promise<void> => {
    await navigate(ROUTES.HOME);
  };

  useEffect(() => {
    return () => {
      removeFromSessionStorage(GuestPaymentAccountStorageKey);
    };
  }, []);
  const PstTime = moment()
    .tz('Canada/Pacific')
    .format('MM/DD/yyyy');
  return {
    accountFinderForm,
    handleOnAccountFinderSubmit,
    accountNotFound,
    isInelgible,
    guestPaymentAccount,
    guestPaymentForm,
    handleOnGuestPayNext,
    paymentDate: formatDate(new Date(PstTime), 'MM/dd/yyyy'),
    handleOnGuestPaymentSubmit,
    isInFlight,
    transactionResults,
    handleDoneClick,
    clearGuestPaymentData,
    handleOnGuestPayBack,
    handleOnGuestPayContinue,
    handleOnGuestPayBackFromVerification,
    handleOnGuestPayCancelFromVerification,
    guestPaymentAuthForm,
  };
};
