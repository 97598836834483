import { Typography } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import React, { FunctionComponent, useEffect } from 'react';

import { useTranslation } from '../../../hooks/useTranslation';
import ROUTES from '../../../routes';
import MaskedNameTextField from '../../masked-name-text-field';
import GuestPaymentNotificationSection from './guest-paymen-notification-section';
import GuestPaymentHeaderSection from './guest-payment-header-section';
import GuestPaymentInfoSection from './guest-payment-info-section';
import ProceedOrCancel from '../../proceed-or-cancel';
import { FormState } from '../../../hooks/useFormState.types';
import { Model } from './GuestPaymentForm.rules';
import { GuestPaymentAccount } from '../GuestPaymentTypes';
import { navigate } from '@reach/router';
import Divider from '@material-ui/core/Divider';
import useStyles from './GuestPaymentForm.styles';

interface Props {
  path?: string;
  guestPaymentForm: FormState<Model>;
  guestPaymentAccount: GuestPaymentAccount;
  handleOnGuestPayNext: () => void;
  handleOnGuestPayCancel: () => void;
  paymentDate: string;
}

const GuestPaymentForm: FunctionComponent<Props> = props => {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    guestPaymentForm,
    guestPaymentAccount,
    handleOnGuestPayNext,
    handleOnGuestPayCancel,
    paymentDate,
  } = props;

  useEffect(() => {
    if (guestPaymentAccount.encryptedAccountNumber.length <= 0) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      navigate(ROUTES.GUEST_PAY);
    }
  }, []);

  return (
    <Grid container spacing={1} className={classes.mainContainer}>
      <div style={{ padding: '1em' }} />
      <Grid item xs={12}>
        <Typography variant={'h1'}>{t('GUEST_PAYMENT')}</Typography>
      </Grid>
      <div style={{ padding: '.25em' }} />
      <GuestPaymentHeaderSection
        accountNumber={guestPaymentAccount.maskedAccountNumber}
        partialAddress={guestPaymentAccount.partialAddressLineOne}
        zipCode={guestPaymentAccount.zipCode}
      />
      <div style={{ padding: '.75em' }} />
      <Paper>
        <Card>
          <CardContent>
            <GuestPaymentInfoSection
              form={guestPaymentForm}
              paymentDate={paymentDate}
            />
            <Divider />
            <GuestPaymentNotificationSection form={guestPaymentForm} />

            <Grid item xs={12} className={classes.noteContainer}>
              <Typography variant={'subtitle2'} style={{ marginRight: '3px' }}>
                {t('NOTE')}
              </Typography>
              <Typography variant={'body2'} style={{ marginTop: '1px' }}>
                {t('GUEST_PAY_NOTE')}
              </Typography>
            </Grid>
          </CardContent>
        </Card>
      </Paper>
      <Grid item xs={12} className={classes.buttonContainer}>
        <ProceedOrCancel
          proceedHandler={handleOnGuestPayNext}
          proceedLabel={t('NEXT')}
          cancelHandler={handleOnGuestPayCancel}
          cancelRoute={ROUTES.GUEST_PAY}
          cancelVariant="Button"
          cancelLabel={t('BACK')}
        />
      </Grid>
    </Grid>
  );
};

export default GuestPaymentForm;
