import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

import colors from '../../../themes/main-colors';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 286,
    },
    authorizationBox: {
      margin: 10,
      marginLeft: 0,
    },
    nextButtonBox: {
      marginRight: 0,
    },
    buttonContainer: {
      display: 'flex',
      width: '100%',
      alignItems: 'center',
      marginTop: '1em',
      justifyContent: 'space-between',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'center',
        flexDirection: 'column-reverse',
        width: '80%',
      },
    },
    cancelVerificationLink: {
      paddingLeft: '1em',
      textDecoration: 'none',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'center',
        paddingLeft: '0em',
      },
    },

    nextButton: {
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        marginRight: 8,
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        marginRight: 8,
      },
    },
    infoBox: {
      display: 'flex',
      flexDirection: 'column',
      margin: 0,
      [theme.breakpoints.down('xs')]: {},
      [theme.breakpoints.down('sm')]: {},
      [theme.breakpoints.up('md')]: {},
      [theme.breakpoints.up('lg')]: {},
    },
    infoBoxContent: {
      display: 'flex',
      margin: 0,
      flexDirection: 'row',
      padding: '1em',
      paddingLeft: 0,
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
      [theme.breakpoints.down('sm')]: {},
      [theme.breakpoints.up('md')]: {},
      [theme.breakpoints.up('lg')]: {},
    },
    infoBoxLeftPane: {
      display: 'flex',
      flexDirection: 'column',
      margin: 0,
      [theme.breakpoints.down('xs')]: {},
      [theme.breakpoints.down('sm')]: {},
      [theme.breakpoints.up('md')]: {},
      [theme.breakpoints.up('lg')]: {},
    },
    infoBoxRightPane: {
      display: 'flex',
      flexDirection: 'column',
      margin: 0,
      [theme.breakpoints.down('xs')]: {},
      [theme.breakpoints.down('sm')]: {},
      [theme.breakpoints.up('md')]: {},
      [theme.breakpoints.up('lg')]: {},
    },
    colorTextPrimary: {
      color: colors.noirBlur,
    },
    confirmationCopy: {
      display: 'flex',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
      [theme.breakpoints.up('sm')]: {
        flexDirection: 'row',
      },
      [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
      },
      [theme.breakpoints.up('lg')]: {
        flexDirection: 'row',
      },
    },
    confirmationNumber: {
      [theme.breakpoints.down('xs')]: {},
      [theme.breakpoints.up('sm')]: {
        marginLeft: 3,
        marginRight: 3,
      },
      [theme.breakpoints.up('md')]: {
        marginLeft: 3,
        marginRight: 3,
      },
      [theme.breakpoints.up('lg')]: {
        marginLeft: 3,
        marginRight: 3,
      },
    },
    checkWrapper: {
      display: 'flex',
      flexDirection: 'row',
    },
    checkBox: {
      // display: 'flex',
      // flexDirection: 'row'
    },
    checkCopy: {},
    zipCode: {
      width: '50%',
    },
    phoneAndAccount: {
      margin: 0,
    },
    phoneOrAccountNumber: {
      padding: theme.spacing(1),
      [theme.breakpoints.down('xs')]: {
        display: 'flex',
        flexDirection: 'column',
      },
      [theme.breakpoints.up('sm')]: {
        margin: 0,
        display: 'flex',
        flexDirection: 'column',
      },
      [theme.breakpoints.up('md')]: {
        margin: 0,
        display: 'flex',
        flexDirection: 'row',
      },
      [theme.breakpoints.up('lg')]: {
        margin: 0,
        display: 'flex',
        flexDirection: 'row',
      },
    },
  }),
);

export default useStyles;
