import {
  Box,
  createStyles,
  Grid,
  makeStyles,
  Paper,
  Theme,
  Typography,
} from '@material-ui/core';
import { navigate } from 'gatsby';
import React, { FunctionComponent } from 'react';
import { useTranslation } from '../../../hooks/useTranslation';
import ROUTES from '../../../routes';
import Button from '../../buttons';
import { TransactionResults } from '../GuestPaymentTypes';
import useStyles from './GuestPayTransactionSuccessful.styles';
import { isMobile } from '../../../util/style-utils';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { DownloadAndPrint } from '../../download-print/DownloadAndPrint';

interface Props extends TransactionResults {
  path?: string;
  handleDoneClick: () => Promise<void>;
}

const useCardStyles = makeStyles((theme: Theme) =>
  createStyles({
    border: {
      border: `1px solid ${theme.palette.success.main}`,
    },
    color: {
      color: theme.palette.success.main,
    },
  }),
);

const AlertMessage = (props: Props) => {
  const classes = useStyles();
  const { t, richT } = useTranslation();
  const paymentAmountDisplay = `$${Number(props?.amount).toFixed(2)}`;
  return (
    <>
      <Typography
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
        className={classes.colorTextPrimary}
        color={'textPrimary'}
        variant={'body1'}
        component={'span'}
      >
        <span style={{ flexDirection: 'row' }}>
          {richT('YOUR_AUTHORIZATION_FOR_PAYMENT_STUB', {
            PAYMENT_AMOUNT: paymentAmountDisplay,
          })}
        </span>
        <span style={{ flexDirection: 'row' }}>
          <span>{t('ACCOUNT_HAS_RECEIVED_A_DISCONNECTION_NOTICE')}</span>{' '}
          <a href={`tel:${t('CALL_FOR_ASSISTANCE_NUMBER')}`}>
            <strong>{t('CALL_FOR_ASSISTANCE_NUMBER')}.</strong>
          </a>
        </span>
      </Typography>
      <br />
      <Grid item xs={12} style={{ display: 'flex', flexDirection: 'row' }}>
        <Typography variant={'body1'}>
          {t('ONETIMEPAY_YOUR_CONFIRMATION_NEW')}{' '}
          <Typography
            variant={'body1'}
            className={classes.confirmationNumber}
            component="span"
          >
            <strong>{props?.confirmationNumber}</strong>
          </Typography>
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        style={{
          display: 'flex',
          flexDirection: isMobile() ? 'column' : 'row',
          alignItems: 'flex-start',
          marginTop: '1em',
          marginBottom: '1em',
        }}
      >
        <Typography variant={'body1'}>{t('RECEIPT_OF_PAYMENT_NEW')}</Typography>
        <Typography
          variant={'body1'}
          style={{ marginLeft: isMobile() ? 0 : '.5em' }}
          component="span"
        >
          <strong>{props?.notificationContactValue}</strong>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant={'body1'}>
          {t('PAYMENT_DEDUCTION_SLUG')}.
        </Typography>
      </Grid>
    </>
  );
};

const GuestPayTransactionSuccesful: FunctionComponent<Props> = props => {
  const classes = useStyles();
  const styles = useCardStyles();
  const { t } = useTranslation();
  const PRINT_CONTAINER_ID = 'one-time-print-receipt';

  if (!props.isSuccessful) {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    navigate(ROUTES.GUEST_PAY);
  }

  return (
    <Grid
      container
      spacing={1}
      className={classes.root}
      alignItems={'flex-start'}
      direction={'column'}
      id={PRINT_CONTAINER_ID}
    >
     
        <Grid item>
          <Typography className={classes.colorTextPrimary} variant={'h1'}>
            {t('GUEST_PAYMENT')}
          </Typography>
        </Grid>
       
      <Grid item xs={12}>
        <Paper component={'main'} className={styles.border}>
          <Box style={{ padding: '1em' }}>
            <Grid container>
            <Grid item container direction={'row'} spacing={2} justify='space-between'>
            <Grid item>
              <Grid
                container
                spacing={2}
                direction={'row'}
                alignItems={'center'}
              >
                <Grid item>
                  <Typography
                    variant={'h2'}
                    className={styles.color}
                    style={{ paddingTop: '0.3em' }}
                  >
                    <CheckCircleIcon fontSize="inherit" />
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant={'h2'}>
                    {t('TRANSACTION_SUCCESSFUL')}
                  </Typography>
                </Grid>
              </Grid>
              </Grid>
              <Grid item>             
                  <DownloadAndPrint
                    containerId={PRINT_CONTAINER_ID}
                    excludeSelectors={['[data-noprint]']}
                  />              
              </Grid>
              </Grid>
              <Grid item xs={12} style={{ paddingLeft: '0.5em' }}>
                <Grid container item xs={12} spacing={4}>
                  <Grid container item>
                    <AlertMessage {...props} />
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  display: 'flex',
                  width: '100%',
                  flexDirection: 'row',
                  justifyContent: isMobile() ? 'center' : 'flex-end',
                }}
              >
                <Grid className={classes.submitButton} item>
                  <Button
                    onClick={props.handleDoneClick}
                    type={'submit'}
                    variant={'contained'}
                    color={'primary'}
                    size={'large'}
                    className={classes.submitButton}
                    data-noprint
                  >
                    {t('DONE')}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default GuestPayTransactionSuccesful;
