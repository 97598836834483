import {
  validateAccountNumber,
  validatePhoneNumber,
  validateZipCode,
} from '../../../util/form-validation';
import { IdentificationType } from '../../../__generated__/pge-types';
import { convertValidationRules } from '../../../hooks/useFormState';

export type Model = {
  // this must match the names of the form field
  zipCode: string;
  identificationType: IdentificationType;
  accountNumber: string;
  phoneNumber: string;
};

export default convertValidationRules<Model>(context => {
  const { identificationType } = context.values;
  return {
    // this must match the names of the form fields
    zipCode: validateZipCode,
    accountNumber:
      identificationType === IdentificationType.AccountNumber
        ? validateAccountNumber
        : null,
    phoneNumber:
      identificationType === IdentificationType.PhoneNumber
        ? validatePhoneNumber
        : null,
  };
});
