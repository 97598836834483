import CardContent from '@material-ui/core/CardContent';
import Paper from '@material-ui/core/Paper';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React, { FunctionComponent } from 'react';

import { useTranslation } from '../../hooks/useTranslation';
import ROUTES from '../../routes';
import colors from '../../themes/main-colors';
import TextLink from '../text-link';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2, 1),
  },
  paper: {
    marginTop: theme.spacing(3),
    flexWrap: 'wrap',
  },
  card: {
    minWidth: '2em',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing(2),
    maxWidth: '300px',
  },
  textPrimary: {
    color: colors.noirBlur,
    paddingTop: theme.spacing(1),
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
  },
  link: {
    color: theme.palette.primary.main,
  },
}));

const NavPaneWithSignIn: FunctionComponent = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <Paper className={classes.card}>
        <CardContent className={classes.cardContent}>
          <Typography
            className={classes.textPrimary}
            component={'span'}
            color={'textPrimary'}
            variant={'body1'}
          >
            <TextLink plain className={classes.link} to={ROUTES.SIGN_IN}>
              {t('SIGN_IN')}
            </TextLink>
            {` ${t('NAV_PANE_TEXT')}`}
          </Typography>
        </CardContent>
      </Paper>
    </div>
  );
};

export default NavPaneWithSignIn;
