import { ApolloClient } from 'apollo-client';
import { validateNameField } from '../../../util/form-validation';
import { convertValidationRules } from '../../../hooks/useFormState';

export enum NotificationChannel {
  EMAIL = 'notificationEmail',
  TEXT = 'notificationTextNumber',
}
export type Model = {
  fullName: string;
};

type AdditionalContext = {
  apolloClient: ApolloClient<any>;
  t: (key: string) => string;
};

export default convertValidationRules<Model, AdditionalContext>(context => {
  return {
    fullName: value =>
      validateNameField(context.t('THE_AUTHORIZED_BY_NAME'), value),
  };
});
