import colors from '../../../themes/main-colors';
import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    colorTextPrimary: {
      color: colors.noirBlur,
    },
    orDivider: {
      [theme.breakpoints.up('md')]: {
        marginTop: '5em',
      },
      [theme.breakpoints.down('sm')]: {
        marginTop: 'auto',
      },
    },
    submit: {
      marginTop: '10px',
      margin: theme.spacing(3, 0, 2),
      [theme.breakpoints.down('md')]: {
        width: '100%',
        margin: 'auto',
      },
    },
    backButton: {
      [theme.breakpoints.up('md')]: {
        marginTop: '1em',
      },
      [theme.breakpoints.down('md')]: {
        width: '100%',
        marginTop: '0em',
      },
    },
    buttonWrapper: {
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
    },
    buttonContainer: {
      width: '100%',
      marginTop: '1em',
      marginRight: '.4em',
      justifyContent: 'space-between',
      flexDirection: 'row',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'center',
        flexDirection: 'column-reverse',
        width: '70%',
        marginLeft: '4em',
      },
    },
    radioSubtitle: {
      marginLeft: theme.spacing(4.5),
    },
    mainContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    noteContainer: {
      marginTop: '1.5em',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
    },
  }),
);

export default useStyles;
