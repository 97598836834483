import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import React, { FunctionComponent } from 'react';
import { FormState } from '../../../../hooks/useFormState.types';
import { useTranslation } from '../../../../hooks/useTranslation';
import CurrencyTextField from '../../../currency-text-field';
import PaymentLimitsFees from '../../../paymentus/payment-limits-fees';
import { Model } from '../GuestPaymentForm.rules';
import { isMobile } from '../../../../util/style-utils';

interface Props {
  form: FormState<Model>;
  paymentDate: string;
}

const GuestPaymentInfoSection: FunctionComponent<Props> = props => {
  const { t } = useTranslation();
  const { form, paymentDate } = props;

  return (
    <Grid container>
      <Grid container item xs={12} direction={isMobile() ? 'column' : 'row'}>
        <Grid
          style={{ flexBasis: isMobile() ? 'unset' : '100%' }}
          item
          xs={isMobile() ? 12 : 6}
        >
          <Typography
            variant={'h4'}
            color={'primary'}
            style={{ marginBottom: isMobile() ? 5 : 0 }}
          >
            {t('ENTER_PAYMENT_INFORMATION')}
          </Typography>
        </Grid>
        <Grid
          style={{ flexBasis: isMobile() ? 'unset' : '100%' }}
          container
          xs={isMobile() ? 12 : 6}
          item
          justify={isMobile() ? 'flex-start' : 'flex-end'}
        >
          <Grid item style={{ paddingTop: 1 }}>
            <PaymentLimitsFees></PaymentLimitsFees>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} style={{ marginTop: '2em' }}>
        <Typography variant={'h3'}>Payment date: {paymentDate}</Typography>
      </Grid>
      <Grid item xs={12} style={{ marginTop: '2em', marginBottom: '1em' }}>
        <CurrencyTextField
          name={'amount'}
          {...form.props('amount')}
          defaultValue={form.values.amount}
        />
      </Grid>
    </Grid>
  );
};

export default GuestPaymentInfoSection;
