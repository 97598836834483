import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import React, { FunctionComponent } from 'react';
import { useTranslation } from '../../../../hooks/useTranslation';

interface Props {
  accountNumber: string;
  partialAddress: string;
  zipCode: string;
}

const GuestPaymentHeaderSection: FunctionComponent<Props> = props => {
  const { t } = useTranslation();

  return (
    <Paper style={{ boxShadow: 'none' }}>
      <CardContent>
        <Grid
          container
          spacing={2}
          style={{ display: 'flex', flexDirection: 'row' }}
        >
          <Grid item xs={12} md={4}>
            <Grid item xs={12}>
              <Typography variant={'body2'}>{t('ACCOUNT_NUMBER')}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant={'subtitle2'}>
                {props?.accountNumber}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} md={4}>
            <Grid item xs={12}>
              <Typography variant={'body2'}>
                {t('SERVICE_ADDRESS_BEGINS_WITH')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant={'subtitle2'}>
                {props?.partialAddress}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} md={4}>
            <Grid item xs={12}>
              <Typography variant={'body2'}>{t('ZIP_CODE')}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant={'subtitle2'}>{props?.zipCode}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Paper>
  );
};

export default GuestPaymentHeaderSection;
