import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import React, { FunctionComponent, useState, useEffect } from 'react';

import ROUTES from '../../../routes';
import { useTranslation } from '../../../hooks/useTranslation';
import AccountTextField from '../../account-text-field';
import PhoneTextField from '../../phone-text-field';
import SampleBill from '../../sample-bill';
import useStyles from './GuestPayAccountFinder.styles';
import { FormState } from '../../../hooks/useFormState.types';
import { Model } from './GuestPayAccountFinder.rules';
import EitherField from '../../either-field';
import Alerts from '../../alerts';
import PGEButton from '../../buttons';
import TextLink from '../../text-link';
import NumericTextField from '../../numeric-text-field';

type AlertMessage = {
  show: boolean;
  title: string | JSX.Element;
  message: string | JSX.Element;
};

interface Props {
  path?: string;
  form: FormState<Model>;
  handleOnSubmit: () => Promise<any>;
  accountNotFound: boolean;
  isIneligible: boolean;
}

const GuestPayAccountFinder: FunctionComponent<Props> = props => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { form, handleOnSubmit, accountNotFound, isIneligible } = props;

  const [alert, setAlert] = useState<AlertMessage>({
    show: false,
    title: '',
    message: '',
  });

  const alertMessageToDisplay = (
    <Typography variant={'body2'}>
      {t('PLEASE_ENTER_ANOTHER_ACCOUNT_OR_CHECK_OUT')}{' '}
      <TextLink to={ROUTES.PAYMENT_OPTIONS}>{t('OTHER_WAYS_TO_PAY')}.</TextLink>
    </Typography>
  );

  useEffect(() => {
    if (accountNotFound) {
      window.scrollTo(0, 0);
      setAlert({
        show: true,
        title: t('COULD_NOT_FIND_PGE_ACCOUNT'),
        message: alertMessageToDisplay,
      });
      return;
    } else if (isIneligible) {
      window.scrollTo(0, 0);
      setAlert({
        show: true,
        title: t('ACCOUNT_NOT_ELIGIBLE_FOR_GUEST_PAY'),
        message: alertMessageToDisplay,
      });
      return;
    } else {
      setAlert({ show: false, title: '', message: '' });
      return;
    }
  }, [accountNotFound, isIneligible]);

  return (
    <Grid className={classes.root} container spacing={3}>
      <Grid item xs={12}>
        <Typography variant={'h1'}>{t('GUEST_PAYMENT')}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant={'h4'}>{t('ONE_TIME_PAYMENT')}.</Typography>
      </Grid>

      {alert.show && (
        <Grid item xs={12}>
          <Alerts
            disableColorOverride={true}
            isOpen={true}
            severity={'error'}
            variant={'outlined'}
            title={alert.title}
            message={alert.message}
          />
        </Grid>
      )}

      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Grid item xs={12} style={{ margin: '0.5em 0 1.5em 0' }}>
              <Typography className={classes.colorTextPrimary} variant={'h2'}>
                {t('WHAT_ACCOUNT')}?
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                className={classes.colorTextPrimary}
                variant={'body1'}
              >
                {t('IDENTIFY_PGE_ACCOUNT')}:
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} className={classes.marginBot1}>
              <NumericTextField
                autoFocus
                name={'zipCode'}
                variant={'outlined'}
                label={t('ZIP_CODE_LABEL')}
                {...form.props('zipCode')}
                inputProps={{
                  maxLength: 5,
                  minLength: 5,
                }}
              />
            </Grid>
            <Grid item xs={12} className={classes.followContainer}>
              <Typography
                className={classes.colorTextPrimary}
                variant={'body1'}
              >
                {t('ONE_OF_THE_FOLLOWING')}:
              </Typography>
            </Grid>
            <EitherField
              aria-label="identify the pge account number"
              form={form}
              selectorField="identificationType"
              inputFields={['phoneNumber', 'accountNumber']}
              renderLeft={
                <PhoneTextField
                  data-testid="phone-number-inout"
                  name="phoneNumber"
                  label={t('PHONE_NUMBER')}
                  autoComplete={t('PHONE_NUMBER')}
                />
              }
              renderRight={
                <AccountTextField
                  data-testid="acccount-number-input"
                  name="accountNumber"
                  label={t('ACCOUNT_NUMBER')}
                  autoComplete={t('ACCOUNT_NUMBER')}
                />
              }
              rightHelper={
                <div className={classes.radioSubtitle}>
                  <SampleBill tabIndex={-1} showCloseIcon={true} />
                </div>
              }
            />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Box mb={6} className={classes.buttonContainer}>
          <PGEButton
            size={'large'}
            className={classes.nextButton}
            onClick={async () => {
              await handleOnSubmit();
            }}
          >
            {t('NEXT')}
          </PGEButton>
          {(accountNotFound || isIneligible) && (
            <Typography variant={'body2'}>
              {t('CALL_FOR_ASSISTANCE')}{' '}
              <a href={`tel:${t('PGE_PHONE')}`}>{t('PGE_PHONE')}</a>
            </Typography>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default GuestPayAccountFinder;
